import { default as apostilaWlQRpfnji1Meta } from "/app/pages/aprenda/apostila.vue?macro=true";
import { default as curso_45enemmV2F77ocl3Meta } from "/app/pages/aprenda/curso-enem.vue?macro=true";
import { default as assinarPhLgkF7FotMeta } from "/app/pages/assinar.vue?macro=true";
import { default as callbackfff7Pp9GGRMeta } from "/app/pages/auth/callback.vue?macro=true";
import { default as cadastrarV1rRvwuzo0Meta } from "/app/pages/cadastrar.vue?macro=true";
import { default as contaM27weMrYmyMeta } from "/app/pages/conta.vue?macro=true";
import { default as modelos_45de_45redacaoQOCA0nuyAlMeta } from "/app/pages/conteudo/artigos/modelos-de-redacao.vue?macro=true";
import { default as repertorio_45socioculturalWL3IXWH4BcMeta } from "/app/pages/conteudo/artigos/repertorio-sociocultural.vue?macro=true";
import { default as entrarismyQfTvvOMeta } from "/app/pages/entrar.vue?macro=true";
import { default as escreverTHghn6okv8Meta } from "/app/pages/escrever.vue?macro=true";
import { default as escrita_45guiada0ue92LxNcoMeta } from "/app/pages/escrita-guiada.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as manutencaojrtNRCjqGqMeta } from "/app/pages/manutencao.vue?macro=true";
import { default as obrigadoXHzEkhYzQzMeta } from "/app/pages/obrigado.vue?macro=true";
import { default as offlinet06iEtmvkBMeta } from "/app/pages/offline.vue?macro=true";
import { default as recuperarDHP3YKQpffMeta } from "/app/pages/recuperar.vue?macro=true";
import { default as _91correctionId_93o3tsRWf7clMeta } from "/app/pages/redacao/[essayId]/[correctionId].vue?macro=true";
import { default as _91essayId_93hZNO52L9E6Meta } from "/app/pages/redacao/[essayId].vue?macro=true";
import { default as sairurTYANMQBrMeta } from "/app/pages/sair.vue?macro=true";
import { default as temasoiIlxnyFizMeta } from "/app/pages/temas.vue?macro=true";
export default [
  {
    name: "aprenda-apostila",
    path: "/aprenda/apostila",
    component: () => import("/app/pages/aprenda/apostila.vue")
  },
  {
    name: "aprenda-curso-enem",
    path: "/aprenda/curso-enem",
    component: () => import("/app/pages/aprenda/curso-enem.vue")
  },
  {
    name: "assinar",
    path: "/assinar",
    component: () => import("/app/pages/assinar.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: callbackfff7Pp9GGRMeta || {},
    component: () => import("/app/pages/auth/callback.vue")
  },
  {
    name: "cadastrar",
    path: "/cadastrar",
    meta: cadastrarV1rRvwuzo0Meta || {},
    component: () => import("/app/pages/cadastrar.vue")
  },
  {
    name: "conta",
    path: "/conta",
    component: () => import("/app/pages/conta.vue")
  },
  {
    name: "conteudo-artigos-modelos-de-redacao",
    path: "/conteudo/artigos/modelos-de-redacao",
    component: () => import("/app/pages/conteudo/artigos/modelos-de-redacao.vue")
  },
  {
    name: "conteudo-artigos-repertorio-sociocultural",
    path: "/conteudo/artigos/repertorio-sociocultural",
    component: () => import("/app/pages/conteudo/artigos/repertorio-sociocultural.vue")
  },
  {
    name: "entrar",
    path: "/entrar",
    meta: entrarismyQfTvvOMeta || {},
    component: () => import("/app/pages/entrar.vue")
  },
  {
    name: "escrever",
    path: "/escrever",
    component: () => import("/app/pages/escrever.vue")
  },
  {
    name: "escrita-guiada",
    path: "/escrita-guiada",
    component: () => import("/app/pages/escrita-guiada.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "manutencao",
    path: "/manutencao",
    component: () => import("/app/pages/manutencao.vue")
  },
  {
    name: "obrigado",
    path: "/obrigado",
    component: () => import("/app/pages/obrigado.vue")
  },
  {
    name: "offline",
    path: "/offline",
    meta: offlinet06iEtmvkBMeta || {},
    component: () => import("/app/pages/offline.vue")
  },
  {
    name: "recuperar",
    path: "/recuperar",
    meta: recuperarDHP3YKQpffMeta || {},
    component: () => import("/app/pages/recuperar.vue")
  },
  {
    name: "redacao-essayId",
    path: "/redacao/:essayId()",
    meta: _91essayId_93hZNO52L9E6Meta || {},
    component: () => import("/app/pages/redacao/[essayId].vue"),
    children: [
  {
    name: "redacao-essayId-correctionId",
    path: ":correctionId()",
    component: () => import("/app/pages/redacao/[essayId]/[correctionId].vue")
  }
]
  },
  {
    name: "sair",
    path: "/sair",
    meta: sairurTYANMQBrMeta || {},
    component: () => import("/app/pages/sair.vue")
  },
  {
    name: "temas",
    path: "/temas",
    component: () => import("/app/pages/temas.vue")
  }
]