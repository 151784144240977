// import this after install `@mdi/font` package
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { ThemeColors } from '~/domains/global/libs/colors'

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    ssr: true,
    theme: {
      defaultTheme: 'cored',
      themes: {
        cored: {
          dark: false,
          colors: ThemeColors,
        },
      },
    },
  })
  app.vueApp.use(vuetify)
})
